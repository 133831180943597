<template>
    <div class="right-operator-wrap flex_center">
        <div class="flex_center" v-if="$checkIsBaoguan()">
            <el-input class="searchNo_input" v-model="orderNo" @keyup.enter.native.stop="searchNo" placeholder="输入单号/柜号/周数/FBAID/订舱号搜索"></el-input>
            <el-button type="primary" size="mini" icon="el-icon-search" @click.stop="searchNo">查询</el-button>
            <div class="messageBg flex_center" v-if="showMessage" @click="handleGoMessage">
                <el-badge v-if="messageQuery > 0" :value="messageQuery" class="messageBox flex_center" :max="99">
                    <i class="el-icon-chat-dot-square"></i>
                </el-badge>
                <div v-else class="messageBox flex_center">
                    <i class="el-icon-chat-dot-square"></i>
                </div>
            </div>
        </div>

        <el-dropdown size="small" @command="handleCommand" trigger="click">
            <span class="el-dropdown-link">
                <i class="fa fa-user-circle icon"></i>
                <span>{{ username }}</span>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="handleClick()"><i class="el-icon-circle-plus"></i>个人信息({{roleName}})</el-dropdown-item>
                <el-dropdown-item command="logout"><i class="el-icon-switch-button"></i>退出登录</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
    import {getAction} from "../../api/manage";
    import { mapState } from 'vuex'
    import store from '@/store'


    export default {
        name: 'RightOperator',
        data() {
            return {
                username: '',
                roleName: '',
                showMessage: false,
                url: {
                    query: "/sys/sysMsgLog/query",
                    searchNo:'/interface/ttOrder/searchByNoBack',
                },
                orderNo:'',
            }
        },
        computed: {
            ...mapState(['messageQuery', 'sidebarList'])
        },
        mounted() {
            this.username = this.$store.state.user.userInfo.username;
            this.roleName = this.$store.state.user.userInfo.roleName;
            this.sidebarList.forEach(element => {
                if(element.path == "/isystem") {
                    element.children && element.children.forEach(children => {
                        if (children.path == "/isystem/message") {
                            this.showMessage = true;
                            this.getMessageQuery();
                            return;
                        }
                    })
                    return;
                }
            });
        },
        methods: {
            searchNo(){
                if (!this.orderNo || this.orderNo.length ===0){
                    this.$message.warning('请输入单号再搜索');
                    return false;
                }
                getAction(this.url.searchNo,{orderNo:this.orderNo})
                    .then(res=>{
                        if (res.result.find){
                            if (res.result){
                                let ss = localStorage.getItem("USERINFO");
                                var user = JSON.parse(ss);
                                let isSlaes = '0';
                                if ( (user.depStr && user.depStr.indexOf("销售")!==-1) || (user.roleName && user.roleName.indexOf("销售")!==-1 )){
                                    //销售
                                    isSlaes = '1';
                                }
                                let type = res.result.type;
                                let logNoSearch = res.result.logNoSearch;
                                let orderType = res.result.orderType;
                                let orderNo = res.result.orderNo;
                                let router = "/docpage/Edit?orderNo=" + orderNo;
                                if("1" == logNoSearch){
                                    router = router + "&logNoSearch=1";
                                }
                                if ('LCL' === type) {
                                    // if (isSlaes==='1'){
                                    //     //销售
                                    //     // setUpRouteUtil(this.$route.path, '/docpage/EditSale');
                                    //     this.$router.push("/docpage/EditSale?orderNo=" + orderNo);
                                    // }else {

                                     this.$router.push(router);
                                        this.setUpRouteUtil(this.$route.path, "/docpage/Edit");
                                       

                                    // }
                                }else if ("FCL"===type){
                                    // if (isSlaes==='1'){
                                    //     //销售
                                    //     // setUpRouteUtil(this.$route.path, '/docpage/EditSaleFCL');
                                    //     this.$router.push("/docpage/EditSaleFCL?orderNo=" + orderNo);
                                    // }else {
                                       this.$router.push(router);
                                       this.setUpRouteUtil(this.$route.path, "/docpage/Edit");
                                     

                                    // }
                                }else if("KD" === type){
                                    // setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                                       this.$router.push('/kdorder/Edit?orderNo=' + orderNo);
                                      this.setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                                 
                                }else if("WEEK" === type){
                                  // setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                                    //判断是否有权限
                                    let flag = this.$btnIsShow('isystem/RoleManage','0','客服页-点击周数跳转到柜子详情页');
                                    if (!flag) {
                                        this.$message.warning('您没有权限访问柜子详情');
                                        return false;
                                    }else {
                                            this.$router.push("/operation/cabinet/Edit?id=" + orderNo);
                                          this.setUpRouteUtil(this.$route.path, "/operation/cabinet/Edit");
                                    
                                        
                                    }
                                }
                                // else if ("DCZ" === type) {
                                //   this.$router.push("/hyFinanceManage/modules/pay/waitVerify?orderNo=" + orderNo + "&orderType="+orderType +"&refresh=true");
                                // }else if ("DXZ" === type) {
                                //   this.$router.push("/hyFinanceManage/modules/pay/waitMerge?orderNo=" + orderNo + "&orderType="+orderType +"&refresh=true");
                                // }else if ("YXZ" === type) {
                                //   this.$router.push("/hyFinanceManage/modules/pay/payRemoved?orderNo=" + orderNo + "&orderType="+orderType +"&refresh=true");
                                // }
                            }
                        }else {
                            this.$message.warning(res.message);
                        }
                    })
            },
            getMessageQuery() {
                getAction(this.url.query)
                    .then((res) => {
                        if (res.success) {
                            store.commit("setMessageQuery", res.result);
                        }
                    })
                    .catch((err) => {});
            },
            async handleCommand(e) {
                if ("logout" === e) {
                    await this.$store.dispatch('user/resetToken').then(() => {

                        console.log('原来的store',this.$store)
                        this.$router.push({'name':'Login'});
                        localStorage.clear();
                        store.commit("setToken", '');
                        // location.reload();
                        console.log('后来的store',this.$store);
                        //清除缓存
                    })
                }
            },
            handleClick(){
                //alert('button click');
                //通过push进行跳转
                if ("供应商" != this.roleName){
                    this.$router.push('/isystem/PersonInfo')
                }
            },
            // 跳转我的消息页面
            handleGoMessage() {
                this.$router.push('/isystem/message')
            },
            getRole() {

                //缓存
                getAction(this.url.list,{isStore:'1',pageSize:1000})
                    .then(res=>{
                        let list = res.result.records;
                        for (var i=0;i<list.length;i++){
                            // console.log('老实点查询字典列表',list[i].dictCode);
                            this.getDictList(list[i].dictCode);
                        }

                    })
            },
            setUpRouteUtil (fromPath, toPath) {
  let menuUpRoute = JSON.parse(localStorage.getItem('menuUpRoute')) || [];
  let isMenuUpRoute = false;  //是否当前路由已经在缓存当中，目前只适配当前路由的上一层路由不在菜单中，深层的暂时不适配
  let path = {
    'from': fromPath,
    'to': toPath
  };
  if(menuUpRoute && menuUpRoute.length>0) {
    // 处理当前路由的上一层路由不在菜单中
    menuUpRoute.forEach((element,index) => {
      if(fromPath == element.to) {
          path = {
            'from': element.from,
            'to': toPath
          }
      }
    });
    menuUpRoute.forEach((element,index) => {
      if(element.to == toPath) {
        isMenuUpRoute = true; 
        menuUpRoute.splice(index,1,path);
        localStorage.setItem('menuUpRoute', JSON.stringify(menuUpRoute));
      }
    });
  }
  if(isMenuUpRoute === false) {
    menuUpRoute.push(path);
    localStorage.setItem('menuUpRoute', JSON.stringify(menuUpRoute));
  }
  store.commit('setUpRoute', path.from);
}
        },
    }
</script>

<style lang="scss" scoped>
    .right-operator-wrap {
        .icon {
            margin: 0 4px 4px 0;
            vertical-align: middle;
            font-size: 24px;
        }
        .el-dropdown-link {
            height: 100%;
            display: inline-block;
            .fa-user-circle {
                font-size: 15px !important;
            }
        }
        .el-dropdown-link{
            padding: 0 12px;
            cursor: pointer;
            &:hover {
                background: rgba(0,0,0,.025);
            }
        }
        .messageBox {
            .el-icon-chat-dot-square {
                font-size: 18px !important;
            }
        }
        .messageBg {
            height: 40px;
            padding: 0 12px;
            cursor: pointer;
            &:hover {
                background: rgba(0,0,0,.025);
            }
        }
        .searchNo_input {
            width: 280px;
            margin-right: 12px;
        }
    }
</style>
<style lang="scss">
    .searchNo_input {
        .el-input__inner {
            line-height: 28px;
            height: 28px;
        }
    }
</style>
