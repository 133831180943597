import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Element from 'element-ui'
import btnPermission from "./plugin/btnPermission";
import 'babel-polyfill';
import 'es6-promise/auto';
import globalVariable from "@/utils/globalVariable";

// import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import 'font-awesome/css/font-awesome.min.css'
import './style/element-variables.scss'
import './style/base_common.scss'
import './style/common.scss'

import './style/process-designer.scss'
import './style/process-panel.scss'
import "./utils/px2rem"

import FTable from '@/components/common/FTable'
import FSelect from '@/components/common/FSelect'
import selectInput from '@/components/common/selectInput/selectInput'
import FUpload from '@/components/common/FUpload'
import htmlToPdf from '@/components/utils/htmlToPdf'
import PreviewImg from '@/components/common/previewImgDialog'

import MyProcessDesigner from "./views/isystem/activity/plugins/index";
import MyProcessPenal from "./views/isystem/activity/refactor/index";


import { vuePlugin } from "./views/isystem/activity/plugins/highlight";
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import VueClipboard from 'vue-clipboard2';
import moment from 'moment'

import { UTable, UTableColumn,UxGrid, UxTableColumn } from 'umy-ui';
Vue.component(UTable.name, UTable);
Vue.component(UTableColumn.name, UTableColumn);
Vue.component(UxGrid.name, UxGrid);
Vue.component(UxTableColumn.name, UxTableColumn);
// import 'umy-ui/lib/theme-chalk/index.css';


Vue.prototype.$momonet = moment
Vue.use(VueClipboard);
Vue.use(Element)
Vue.use(MyProcessDesigner)
Vue.use(MyProcessPenal)
Vue.use(vuePlugin)
Vue.use(htmlToPdf)
Vue.use(btnPermission)
Vue.use(Viewer)

Vue.prototype.$bus = new Vue()
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.GLOBAL = globalVariable

Vue.component('FTable', FTable)
Vue.component('FSelect', FSelect)
Vue.component('selectInput', selectInput)
Vue.component('FUpload', FUpload)
Vue.component('PreviewImg', PreviewImg)
// Vue.component('MyProcessDesigner', MyProcessDesigner)
// Vue.component('MyProcessPenal', MyProcessPenal)

// 获取浏览器的唯一标识id
FingerprintJS.load().then(fp => {
  fp.get().then(result => {
      localStorage.setItem('browser_id', result.visitorId);
      console.log(result.visitorId,'浏览器的标识id');
  });
});

new Vue({
  router,
  store,
  render: h => h(App),
  data : function () {
    return {
      btnObj:{},
    }
  }
}).$mount('#app')
